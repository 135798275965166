var TopMenuComponent = /** @class */ (function () {
    function TopMenuComponent() {
    }
    Object.defineProperty(TopMenuComponent.prototype, "filteredNodes", {
        //Lator will remove it
        get: function () { return this.nodes ? this.nodes.filter(function (n) { return !n.hidden; }) : []; },
        enumerable: true,
        configurable: true
    });
    return TopMenuComponent;
}());
export { TopMenuComponent };
